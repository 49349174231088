













import { Component, Vue } from 'vue-property-decorator';
import introduceData from '../../../assets/data/introduce'

@Component({})
export default class extends Vue {

  private teamsData = introduceData.teams

}
