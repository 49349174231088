












import { Component, Vue } from 'vue-property-decorator'
import { getNews } from '../../../api'

@Component({})
export default class extends Vue {

  private list = []

  get listHandled(){
    return this.list.map((item:any)=>{
      item.pubDate = item.pubDate.split(' ')[0]
      return item
    })
  }

  async getNews(){
    const { records } = await getNews({pageNo:1,pageSize:5})
    this.list = records
  }

  mounted(){
    this.getNews()
  }
}
