export default{
  banner: {
    titleImg: require('../images/introduce/banner_title.png'),
    video:{
      src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/72849dd-179407a33f9-0005-502e-0e5-a9c95.mp4',
      poster: 'http://www.xmheigu.com/images/ban-p5.jpg',
      title: '',
      dec: '实体门店如何在当今经营局势下找到突破口？转变经营思维，创新商业模式是出路。黑谷在商业模式+管理软件+落地服务三大版块助力门店老板解决经营问题。如何让门店营业额快速翻倍，黑谷可以帮你。'
    }
  },
  cards: [
    {
      title: '公司概况',
      content: '厦门黑谷网络科技股份有限公司专业、专注于汽车服务行业，11年行业经验，致力于打造汽车服务行业的“新盈利模式”， 主要从事汽车维修、美容管理系统研发以及营销方案的策划，针对门店的资金，客源，员工，管理等一系列问题，提供一整套完善的门店解决方案。共享店铺模式，超级员工模式，获客策划等新型商业模式，秉承助力汽车服务行业转型升级的经营理念，不断将前沿的科技成果转化成行业发展的新动力，公司创办至今已为30000多家门店提供门店整体解决方案，获得业内一致好评。如今的黑谷以更加旺盛的斗志，持续为广大客户提供优质的服务，进一步推动汽车后市场的转型升级。'
    },
    {
      title: '黑谷事迹',
      list: [
        '2020年中国品牌创新发展论坛“中国品牌榜·诚信品牌奖”',
        '2020年中国品牌创新发展论坛“中国品牌榜·领军人物奖”',
        '2019中国最佳商业模式创新奖',
        '2019中国经济新模式十大创新企业',
        '2019中国经济新模式十大创新人物',
        '2019CIAAF|ACTF郑州展行业最具人气品牌',
        '2019年度优选项目'
      ]
    }
  ],
  series: [
    {
      icon: require('../images/introduce/series/series_01.png'),
      name: '共享店铺'
    },
    {
      icon: require('../images/introduce/series/series_02.png'),
      name: '共享商圈'
    },
    {
      icon: require('../images/introduce/series/series_03.png'),
      name: '抖音拓客系统'
    },
    {
      icon: require('../images/introduce/series/series_04.png'),
      name: '员工合伙模式'
    },
    {
      icon: require('../images/introduce/series/series_05.png'),
      name: '客源生态系统'
    },
    // {
    //   icon: require('../images/introduce/series/series_06.png'),
    //   name: '共享演唱会'
    // },
    {
      icon: require('../images/introduce/series/series_07.png'),
      name: '共享校区'
    },
    {
      icon: require('../images/introduce/series/series_08.png'),
      name: '超级管家团队'
    },
    {
      icon: require('../images/introduce/series/series_09.png'),
      name: '共享客服'
    },
    {
      icon: require('../images/introduce/series/series_10.png'),
      name: '各行业管理软件'
    },
    {
      icon: require('../images/introduce/series/series_11.png'),
      name: '微信营销'
    },
    {
      icon: require('../images/introduce/series/series_14.png'),
      name: '黑谷粮仓'
    }
  ],
  teams: [
    {
      name: '讲师团队',
      img: require('../images/introduce/team/js.jpg')
    },
    {
      name: '餐饮事业部',
      img: require('../images/introduce/team/cy.jpg')
    },
    {
      name: '黑谷专家团',
      img: require('../images/introduce/team/zjt.jpg')
    },
    {
      name: '落地运营部',
      img: require('../images/introduce/team/ldyy.jpg')
    },
    {
      name: '企划部',
      img: require('../images/introduce/team/qh.jpg')
    },
    {
      name: '汽车事业部',
      img: require('../images/introduce/team/qcsy.jpg')
    },
    {
      name: '售后一部',
      img: require('../images/introduce/team/shyb.jpg')
    },
    {
      name: '售后二部',
      img: require('../images/introduce/team/sheb.jpg')
    }
  ],
  advantage: {
    title: '合作优势',
    list: [
      {
        title: '市场优势',
        content: '大量的前期投入，拥有核心技术，凭借长期的技术积淀，顺应互联网发展，符合共享经济-分享经济趋势，对市场需求的快速反应，让黑谷的产品研发和在市场应用上拥有突出优势。'
      },
      {
        title: '团队优势',
        content: '集合了优秀的技术人才、资深的营销专家，信息安全专家，以及专业的售后和运营团队，默契的团队协作关系，完善的培训制度，高效的团队执行力。专业的资深团队，标准化的作业体系，一对一的专业服务，让合作没有后顾之忧。'
      },
      {
        title: '战略优势',
        content: '整合优质资源，引入的开发更多切实解决厂家需求，在顾客忠诚度、品牌知名度，市场化、人力占有率、企业资源、核心技术、研发能力，市场应变能力等方面拥有内外综合优势。'
      },
      {
        title: '技术优势',
        content: '强大的研发团队，模式新颖，持续更新，独树一帜。<br/>1、优秀的开发技术，为用户提供更灵活、多层面的解决方案。<br/>2、优质的用户体验，以快速实现用户需求，不断为用户推出更贴心的服务。<br/>3、稳定可靠的产品研发技术，有效保证软件应用的稳定安全。'
      },
      {
        title: '产品优势',
        content: '敏锐的行业嗅觉，新颖的盈利模式，从根源解决门店问题。八大方案满足门店全面需求。功能齐全，操作简单，适用于维修厂，美容店，轮胎店，钣喷店等。<br/>1、终身服务 免费升级！<br/>2、功能齐全 操作简单！<br/>3、团队强大！<br/>4、数据安全！<br/>5、正版软件 免费体验！<br/>6、不断优化 持续更新！'
      },
      {
        title: '运营优势',
        content: '一站式的战略合作服务，为品牌提升，业绩增长提供优质推广环境。拥有丰富的资源，多 样的宣传途径！<br/>1、节约成本：大大节约合作伙伴的时间成本、管理成本等…<br/>2、提高宣传效率：不用再为了销售渠道、销售方法发愁，黑谷营销工具全方位解决产品销售问题，APP，软件广告投放轻松获取优质客源！<br/>3、资源变现：通过资源整合合作，还可将手头的门店资源进行共享，置换'
      }
    ]
  }
}