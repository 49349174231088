



















import { Component, Vue } from 'vue-property-decorator';
import vFreeApply from '@/components/v-free-apply.vue';
import VBanner from '@/components/v-banner.vue';
import VCard from '@/components/v-card.vue'
import introduceData from '../../assets/data/introduce'
import introduceSeries from './components/introduce-series.vue'
import introduceTeams from './components/introduce-teams.vue'
import introduceNews from './components/introduce-news.vue'
import vCardList from '@/components/v-card-list.vue' 

@Component({
  components: { vFreeApply,VBanner, VCard, introduceSeries, introduceTeams, introduceNews, vCardList }
})
export default class extends Vue {
  private bannerData = introduceData.banner
  private introData = introduceData.cards[0]
  private deedsData = introduceData.cards[1]
  private advantage = introduceData.advantage
}
